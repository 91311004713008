import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { updateCase } from "../services/api/cases.js";
import { getAttachment, generateDownloadLink, uploadThumbnail, generateManifestLink, getShares, getAttachments} from "../services/api/attachments.js";
import CommentSection from "./CommentSection.js";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import CaseShareModal from "./CaseShareModal.js";
import { dateFromTimestamp } from "../services/util/metadata.js";
import { deleteAttachment } from "../services/api/attachments.js";
import Timeline from "./Timeline.js";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { hmsTextFromSeconds, reduceStringWords } from "../services/util/metadata.js";
import { getCurrentVideoFrame } from "../services/util/video.js";
import VideoPlayer from "./VideoPlayer.js";
import videojs from "video.js";
import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import WatchNext from "./WatchNext.js";
import useWindowSize from "../hooks/useWindowSize.js";
import { requestNewExport, getExports } from "../services/api/exports.js";
import ExportDownload from "./ExportDownload.js";
import { useSearchParams } from "react-router-dom";
import SymbolBubble from "./SymbolBubble.js";

const Viewer = (props) => {

    const { attachment_id } = useParams();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();

    const mounted = useRef(true);

    const [attachment, setAttachment] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);

    const user = useSelector(state => state.user.value);
    const playerRef = useRef(null);

    const [caseInfo, setCaseInfo] = useState(null);
    const [editedCaseTitle, setEditedCaseTitle] = useState('');
    const [editedCaseDescription, setEditedCaseDescription] = useState('');

    const [expandDescription, setexpandDescription] = useState(false);
    const [showEditCaseDetailsModal, setShowEditCaseDetailsModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [exportData, setExportData] = useState(null);
    const windowSize = useWindowSize();
    const [mobilePanel, setMobilePanel] = useState('comments');

    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
      setShowExportModal(searchParams.get('showExport') === 'true');
    }, [searchParams]);

    useEffect(() => {
        // get the manifest
        generateManifestLink(attachment_id)
        .then(data => data['manifest_link'])
        .then(download_link => {
          setDownloadLink(download_link);
          console.log('manifest link:', download_link);
        })
        .catch( (error) => {
          // the manifest doesn't exist
          console.log('manifest not found')
          Sentry.captureException(error);
        }
        )

        // get the attatchment details
        getAttachment(attachment_id)
        .then((item) => {
            setAttachment(item);
            setCaseInfo({id: item.case.id, case_name: item.case.case_name, description: item.case.description, owner: item.case.owner, created_on: item.created_on});
            setEditedCaseTitle(item.case.case_name);
            setEditedCaseDescription(item.case.description);
            try {
              mixpanel.track('Viewed Attachment', 
                {
                  'Source': 'Viewer Page', 
                  'Attachment ID': attachment_id,
                  'Attachment Owner': item.case.owner.id,
                }
              );
            } catch (error) {
              Sentry.captureException(error);
            }
        })

        //get export data
        getExports({'attachment_id': attachment_id}).then((results) => {
          if (results.length > 0) {
            setExportData(results[0]);
          }
        });

    }, [attachment_id]);

    useEffect(() => {
      if (attachment && downloadLink) {
          //check to see if attachment_edit.m3u8 exists
          //if it does, the case is editable
          fetch(downloadLink.replace('attachment.m3u8', 'attachment_edit.m3u8'),
          {
            method: 'GET',
            mode: 'cors',
            credentials: 'include'
          })
          .then(response => {
            if (response.ok) {
              setIsEditable(true);
            }
          })
      }
    }, [downloadLink, attachment]);

    const handleExport = (e) => {
      e.preventDefault();
      requestNewExport(attachment_id).then((results) => {
        setExportData(results);
      });
    };

    const handleDelete = (e) => {
      e.preventDefault();
      if (!showDeleteConfirmModal)
        setShowDeleteConfirmModal(true);
      else {
        //delete the case
        deleteAttachment(attachment.id).then(() => {
          //navigate to library
          navigate('/library');
        })
      }
    };

    const handleShare = (e) => {
      e.preventDefault();
      setShowShareModal(true);
    };

    const handleEditCaseDetails = (e) => {
      e.preventDefault();
      //pop up a bootstrap Modal to edit case details
      //set the state of the modal to show
      if (!showEditCaseDetailsModal)
        setShowEditCaseDetailsModal(true);
      else {
        //update the case details
        caseInfo.case_name = editedCaseTitle;
        caseInfo.description = editedCaseDescription;
        //close the modal
        updateCase(caseInfo.id, {
          "case_name": editedCaseTitle,
          "description": editedCaseDescription
        })
        setShowEditCaseDetailsModal(false);
      }
    };

    const handleSetThumbnail = (e) => {
      e.preventDefault();
      getCurrentVideoFrame(playerRef, (dataURL) => {
        //upload the thumbnail to the attachment
        fetch(dataURL)
        .then(res => res.blob())
        .then(blob => uploadThumbnail(attachment.id, blob))
      })
    };

    const [playerReady, setPlayerReady] = useState(false);
    const handlePlayerReady = (player) => {
      playerRef.current = player;
      setPlayerReady(true);
      // You can handle player events here, for example:
      player.on('waiting', () => {
        videojs.log('player is waiting');
      });
  
      player.on('dispose', () => {
        videojs.log('player will dispose');
      });
    };

    return ( 
      <>
      { windowSize.width > 900 ?
        <Container>
          <PageMain>
            {downloadLink && attachment &&
              <PlayerContainer>
                <VideoPlayer
                  overlayControls={true}
                  options={{
                    controls: true,
                    playsinline: true,
                    fill: true,
                    autoplay: 'muted',
                    crossOrigin: 'use-credentials',
                    aspectRatio: '16:9',
                    enableSmoothSeeking: true,
                    playbackRates: [1, 1.5, 2],
                    html5: {
                      vhs: {
                        "withCredentials": true,
                        // "overrideNative": true,
                        "useDevicePixelRatio": true,
                      },
                      // nativeAudioTracks: false,
                      // nativeVideoTracks: false
                    } 
                  }}
                  src={`${downloadLink}`}
                  onReady={handlePlayerReady}
                  info={
                    {
                      attachment_id: attachment_id,
                      owner_id: attachment.case.owner.id,
                      initial_time: searchParams.get('t') ? parseFloat(searchParams.get('t')) : attachment.user_view,
                    }
                  }
                />
              </PlayerContainer>
            }
            {caseInfo && attachment &&
            <VideoDetailsContainer>
              <VideoBylineContainer>
                <VideoByline>
                  <h4>{ caseInfo.case_name }</h4>
                  <p>{ caseInfo.owner.first_name } { caseInfo.owner.last_name }</p>
                </VideoByline>
                  <ButtonGroup>
                  {attachment.permissions.can_reshare &&
                    <Button onClick={(e) => handleShare(e)} style={{marginRight: '2px'}}>Share</Button>
                  }
                  {isEditable && attachment.permissions.can_edit &&
                  <Button style={{marginRight: '2px'}}>
                      <Link
                        style={{color: 'white', textDecoration: 'none'}}
                        to={`/editor/${attachment_id}`}
                        >
                          Edit
                      </Link>
                  </Button>
                  }
                  {((user && user.id === attachment.case.owner.id) || attachment.permissions.can_export) &&
                  <DropdownButton as={ButtonGroup} align="end" id="more" title="Options">
                    {user && user.id === attachment.case.owner.id &&
                    <>
                      <Dropdown.Item onClick={handleEditCaseDetails}>Edit Description</Dropdown.Item>
                      <Dropdown.Item onClick={handleSetThumbnail}>Set Thumbnail</Dropdown.Item>
                      <Dropdown.Divider/>
                    </>
                    }
                    {attachment.permissions.can_export &&
                    <Dropdown.Item onClick={() => setShowExportModal(true)}>
                      Export
                    </Dropdown.Item>
                    }
                    {user && user.id === attachment.case.owner.id &&
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={handleDelete}>Delete Case</Dropdown.Item>
                    </>
                    }
                  </DropdownButton>
                  }
                  {`\u00A0`}
                  </ButtonGroup>
              </VideoBylineContainer>
              <VideoDetails>
                  <b>
                      { dateFromTimestamp(caseInfo.created_on) } 
                      {/* { caseInfo.case_type && (' \u2022 ' + caseInfo.case_type) } */}
                      {attachment.user_metadata?.duration && 
                        ' \u2022 ' + hmsTextFromSeconds(attachment.user_metadata?.duration)
                      }
                      {(attachment.markers?.length > 0) && 
                          (`  \u2022  ` + attachment.markers?.length + 
                          ` chapter` + (attachment.markers?.length>1 ? `s` : ``))
                      }
                  </b>
                    {caseInfo.description && (caseInfo.description.split(" ").length > 12) ? (
                    <div align='left'>
                      {!expandDescription ? 
                      (<span>
                        {reduceStringWords(caseInfo.description, 12)}
                        <b><a onClick={() => setexpandDescription(true)}> ...more </a></b>
                      </span>) : (
                        <div>
                          <p align='justify'>{ caseInfo.description }</p>
                          <a onClick={() => setexpandDescription(false)}>Show Less</a>
                        </div>)}
                    </div>) : (<p align='justify'>{ caseInfo.description }</p>)}

              </VideoDetails>
            </VideoDetailsContainer>
            }
            {attachment && playerReady && 
            <CommentSection 
              attachment_id={attachment_id} 
              playerRef={playerRef} 
              comment_count={attachment.public_comment_count}/>
            }
          </PageMain> 
          <PageRight>
            {playerReady && 
              <TimelineContainer>
                <Timeline playerRef={playerRef} attachment={attachment}/>
              </TimelineContainer>
            }
            <WatchNextContainer>
              <div className='title'>
                  Watch Next...
              </div>
              <WatchNext nVideos={2} attachment_id={attachment_id}/>
            </WatchNextContainer>
          </PageRight>
          <Modal
            show={showExportModal}
            onHide={() => {setShowExportModal(false)}}
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Export Video
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p align='left'>
                <ExportDownload
                  exportData={exportData}
                  attachment={attachment}
                  handleExport={handleExport}
                />
              </p>
            </Modal.Body>
          </Modal>
          <Modal
            show={showEditCaseDetailsModal}
            onHide={() => setShowEditCaseDetailsModal(false)}
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Edit Case Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Title</Form.Label>
                      <Form.Control 
                        type="text" 
                        value={editedCaseTitle}
                        onChange={(e) => setEditedCaseTitle(e.target.value)}
                       />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control 
                        as="textarea" 
                        rows={3} 
                        value={editedCaseDescription}
                        onChange={(e) => setEditedCaseDescription(e.target.value)}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicEmail">
                    <Form.Label>Tags</Form.Label>
                    <Form.Control type="text" placeholder="Enter tags" />
                    </Form.Group> */}
                </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={(e) => handleEditCaseDetails(e)}>Save</Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showDeleteConfirmModal}
            onHide={() => setShowDeleteConfirmModal(false)}
            size="md"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Delete Case
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p align='center'>Are you sure you want to delete this case?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={(e) => handleDelete(e)}>Delete</Button>
              <Button onClick={() => setShowDeleteConfirmModal(false)}>Cancel</Button>
            </Modal.Footer>
          </Modal>
          {attachment &&
          <CaseShareModal 
            show={showShareModal}
            onHide={() => setShowShareModal(false)}
            onSave={() => {}}
            attachment={attachment}
            user={user}
          />}
        </Container>
        :
        <Container>
        {downloadLink && attachment &&
              <PlayerContainer>
                <VideoPlayer
                  options={{
                    controls: true,
                    playsinline: true,
                    fill: true,
                    autoplay: 'muted',
                    crossOrigin: 'use-credentials',
                    aspectRatio: '16:9',
                    enableSmoothSeeking: true,
                    playbackRates: [1, 1.5, 2],
                    html5: {
                      vhs: {
                        "withCredentials": true,
                        "useDevicePixelRatio": true,
                        // "overrideNative": true,
                      },
                    } 
                  }}
                  src={`${downloadLink}`}
                  onReady={handlePlayerReady}
                  info={
                    {
                      attachment_id: attachment_id,
                      owner_id: attachment.case.owner.id,
                    }
                  }
                />
              </PlayerContainer>
            }
            <PageMain>
            {caseInfo && attachment &&
            <VideoDetailsContainer>
              <VideoBylineContainer>
                <VideoByline>
                  <h4>{ caseInfo.case_name }</h4>
                  <span>{ caseInfo.owner.first_name } { caseInfo.owner.last_name }</span>
                  <div style={{color: 'gray'}}>{dateFromTimestamp(caseInfo.created_on) } 
                      {/* { caseInfo.case_type && (' \u2022 ' + caseInfo.case_type) } */}
                      {attachment.user_metadata?.duration && 
                        ' \u2022 ' + hmsTextFromSeconds(attachment.user_metadata?.duration)
                      }
                      {(attachment.markers?.length > 0) && 
                          (`  \u2022  ` + attachment.markers?.length + 
                          ` chapter` + (attachment.markers?.length>1 ? `s` : ``))
                      }
                  </div>
                </VideoByline>
              </VideoBylineContainer>
            </VideoDetailsContainer>
            }
            <SelectionBar>
              <ItemSelector 
                onClick={(e) => setMobilePanel('chapters')}
                isActive={mobilePanel === 'chapters'}
              >
                Chapters
              </ItemSelector>
              <ItemSelector 
                onClick={(e) => setMobilePanel('comments')}
                isActive={mobilePanel === 'comments'}
              >
                Comments
              </ItemSelector>
              <ItemSelector 
                onClick={(e) => setMobilePanel('details')}
                isActive={mobilePanel === 'details'}
              >
                Details
              </ItemSelector>
              <ItemSelector 
                onClick={(e) => setMobilePanel('watch')}
                isActive={mobilePanel === 'watch'}
              >
                Watch
              </ItemSelector>
            </SelectionBar>
            {attachment && playerReady && 
            ((mobilePanel === 'comments') &&
            <CommentSection 
              attachment_id={attachment_id} 
              playerRef={playerRef} 
              comment_count={attachment.public_comment_count}
              />) ||
            ((mobilePanel === 'chapters') &&
              <Timeline playerRef={playerRef} attachment={attachment}/>
              ) ||
              ((mobilePanel === 'details') &&
              <div style={{textAlign: 'left', marginTop: '10px'}}>
                {caseInfo.description}
              </div>
              ) ||
            ((mobilePanel === 'watch') &&
            <WatchNext nVideos={3} attachment_id={attachment_id}/>)
            }
          </PageMain>
        </Container>
        }
        </>
     );
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px;
    gap: 10px;
    margin: 0;
    width: 100%;
    max-width: 1920px;
    justify-content: center;

    @media (max-width: 899px) {
        position: relative;
        padding: 0;
        align-items: flex-start;
        overflow-y: auto;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;    
        gap: 0px;    
    }

    `;

const PageMain = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    max-width: calc(75vh * 16/9);
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: 899px) {
      max-width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      justify-self: flex-start;
    }
    `;

const PageRight = styled.div`
    display: flex;
    flex: 0 0 270px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    `;

const PlayerContainer = styled.div`
    position: relative;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 7px;
    overflow: hidden;

    @media (max-width: 899px) {
        border-radius: 0;
        justify-content: flex-start;
        align-items: stretch;
        width: 100vw;
        max-height: calc(9/16 * (100vw));
        min-height: calc(9/16 * (100vw));
    }
`;

const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 270px;
    height: 100%;
    max-height: min(calc(9/16 * (100vw - 320px)), calc(9/16 * (75vh * 16/9 - 270px) - 10px));

    @media (max-width: 899px) {
      width: 100%;
      height: 100%;
    }
  `;

const WatchNextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 100%;

    .title {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        align-self: center;
        text-align: left;
    }
  `;

const VideoBylineContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
`;

const VideoByline = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const VideoDetailsContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const VideoDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f5f5f5;
    width: 100%;
    padding: 20px;
    border-radius: 7px;
    font-size: 15px;
    width: 100%;
`;

const SelectionBar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
    border-radius: 7px;
    background-color: #f5f5f5;

`;

const ItemSelector = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: content;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    color: ${props => props.isActive ? 'black' : '#666'};
`;

export default Viewer;